<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <el-tabs v-model="activeName">
        <el-tab-pane label="处理" name="handled"> </el-tab-pane>
        <el-tab-pane label="处理记录" name="noHandled"> </el-tab-pane>
        <component :inspectionType="inspectionType" v-bind:is="activeName"></component>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import handled from "./handled";
import noHandled from "./noHandled";
export default {
  name: "auditConf",
  components: {
    handled,
    noHandled,
  },
  data() {
    return {
      inspectionType: [],
      activeName: "handled",
    };
  },
  methods: {
    getObeyList() {
      this.$axios
        .get("/acb/2.0/inspectionType/list", {
          data: { ...this.formInline, page: this.page, pageSize: this.pageSize },
        })
        .then((res) => {
          this.inspectionType = res.value;
        });
    },
  },
  beforeDestroy() {},
  created() {},
  mounted() {
    this.getObeyList();
  },
  activated() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
>>>.el-tabs__header
  padding-left 0px
  margin: 0
  .el-tabs__nav-wrap {
    height: 40px
    .el-tabs__nav-scroll {
    }
    .el-tabs__nav {
      height 40px
      .el-tabs__item {
        padding: 9px 16px
        height 100%
      }
    }
  }
.content
  background: #fff;
  border-radius: 12px
  overflow: hidden;
  .searchWrapper
    overflow: hidden;
    padding: 22px 22px 0 22px;
    margin-bottom: 20px;
  .pagerWrapper
    text-align: right;
    margin-top: 28px;
    font-size: 12px;
    .export
      font-size: 12px;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;
      .iconfont
        margin-right: 14px;
.detail
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128,145,165,0.30);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;
  .title
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;
    .el-icon-close
      float: right;
      margin-top: 14px;
  .info
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
.mask
  background: rgba(49,53,65,0.60);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
.upload-demo
  display: inline-block;
</style>
<style scoped>
.el-button {
  margin: 0 !important;
}
</style>
