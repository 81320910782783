<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          ref="searchForm"
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Record_number')" prop="inspectionCode">
                <el-input
                  v-model.trim="formInline.inspectionCode"
                  maxlength="30"
                  placeholder="记录编号"
                >
                </el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Violating_personnel')" prop="violators">
                <el-input
                  v-model.trim="formInline.violators"
                  maxlength="20"
                  placeholder="违规人员"
                >
                </el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Violation_type')" prop="type">
                <el-select
                  v-model.trim="formInline.type"
                  filterable
                  placeholder="请选择"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.inspectionTypeDesc"
                    :value="value.inspectionTypeCode + ''"
                    :key="value.inspectionTypeCode"
                    v-for="value in inspectionType"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Inspector')" prop="inspector">
                <el-input
                  v-model.trim="formInline.inspector"
                  maxlength="20"
                  placeholder="稽查人员"
                >
                </el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Belonging_operator')">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                  @change="getOperationInfo"
                >
                  <el-option label="全部" value></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Road_name')" prop="pdaManagerName">
                <el-autocomplete
                  v-model="parkId"
                  class="inline-input"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  value-key="parkName"
                  :trigger-on-focus="false"
                  :clearable="true"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Report_time')" prop="reportTime">
                <el-date-picker
                  v-model="reportTime"
                  type="datetimerange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  prefix-icon="el-icon-time"
                  :default-time="['00:00:00', '23:59:59']"
                >
                </el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
              >
                查询
              </el-button>
              <el-button type="info" icon="el-icon-delete" @click="clearForm"
                >{{ $t('button.reset') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            align="center"
            :index="indexMethod"
          >
          </el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :show-overflow-tooltip="item.showTooltip"
            align="center"
            :key="item.prop"
            :formatter="item.formatter"
          >
          </el-table-column>
          <el-table-column
            prop="state"
            align="center"
            :label="$t('list.state')"
          >
            <template slot-scope="scope">
              <span class="stateWrapper">{{
                stateFormat(scope.row.status)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="90">
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0">
                  操作 <i class="el-icon-arrow-down" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <!-- <el-dropdown-item
                    v-if="$route.meta.authority.button.handle"
                    v-show="scope.row.status == 0"
                    command="a"
                    >处理</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="$route.meta.authority.button.view"
                    command="b"
                    >查看</el-dropdown-item
                  >-->
                  <el-dropdown-item v-show="scope.row.status == 0" command="a"
                    >处理</el-dropdown-item
                  >
                  <el-dropdown-item command="b">查看</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    parkId(ne) {
      if (ne == "") {
        this.formInline.parkId = "";
      }
    },
  },
  name: "handled",
  props: ["inspectionType"],
  data() {
    return {
      reportTime: [],
      tenantList: [],
      parkId: "",
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      datetime: [],
      tableCols: [
        {
          prop: "inspectionCode",
          label: this.$t("list.Record_number"),
          width: "150",
        },
        {
          prop: "violatorsName",
          label: this.$t("list.Violating_personnel"),
          width: "",
        },
        {
          prop: "typeStr",
          label: this.$t("list.Violation_type"),
          width: "",
          showTooltip: true,
        },
        {
          prop: "inspectorName",
          label: this.$t("list.Inspector"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Belonging_operator"),
          width: "",
        },
        {
          prop: "parkName",
          label: this.$t("list.Road_name"),
          width: "",
        },
        {
          prop: "reportTime",
          label: this.$t("list.Report_time"),
          width: "180",
        },
      ],
      tableData: [],
      formInline: {
        inspectionCode: "",
        inspector: "",
        parkId: "",
        reportTimeEnd: "",
        reportTimeStart: "",
        type: "",
        violators: "",
        operationId: "",
      },
    };
  },
  methods: {
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    // 所属运营商选择
    getOperationInfo(val) {
      this.formInline.operationId = val;
    },
    // 所属运营商下拉框
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      // 将原字符串中的所有空白字符替换成""
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            slaveRelations: "0,1",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    stateFormat(state) {
      if (state == 0) {
        return "待审批";
      }
      if (state == 1) {
        return "已撤回";
      }
      if (state == 2) {
        return "驳回";
      }
      if (state == 3) {
        return "审批通过";
      }
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$router.push({
          path: "/auditCheck",
          query: data,
        });
      }
      if (cmd == "b") {
        this.$router.push({
          path: "/auditDetail",
          query: data,
        });
      }
    },
    clearForm() {
      this.parkId = "";
      this.reportTime = [];
      // this.tenantList = []
      this.formInline.operationId = "";
      this.$refs.searchForm.resetFields();
    },
    indexMethod(index) {
      return index + 1;
    },
    getObeyList() {
      this.$axios
        .get("/acb/2.0/inspectionType/list", {
          data: {
            ...this.formInline,
            page: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.inspectionType = res.value.list;
          this.total = res.value.total * 1;
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    // 搜索
    searchData() {
      if (this.reportTime && this.reportTime.length != 0) {
        this.formInline.reportTimeStart = this.reportTime[0];
        this.formInline.reportTimeEnd = this.reportTime[1];
      } else {
        this.formInline.reportTimeStart = "";
        this.formInline.reportTimeEnd = "";
      }
      this.$axios
        .get("/acb/2.0/inspection/list", {
          data: {
            ...this.formInline,
            page: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.tableData = res.value.list;
          this.total = res.value.total * 1;
        });
    },
  },
  beforeDestroy() {},
  created() {
    this.searchData();
    this.getTenantList();
  },
  mounted() {},
  activated() {
    this.searchData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    // margin-bottom: 20px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.upload-demo {
  display: inline-block;
}
</style>
<style scoped>
.el-button {
  /* margin: 0 !important; */
}
.el-icon-date-custom {
  position: relative;
  left: -26px;
}
.stateWrapper {
  display: inline-block;
  padding: 3px 10px;
  height: 25px;
  background: orangered;
  color: white;
  border-radius: 5px;
  vertical-align: middle;
}
</style>
